import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";

import Header from "./../components/header";
import Footer from "./../components/footer";
import LoaderCircle from "./../components/loader-circle";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  refreshing,
  user,
  location,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Suspense fallback={<LoaderCircle isLoading={true} />}>
            <LoaderCircle isLoading={refreshing} />
            {isAuthenticated &&
              user.verified !== undefined &&
              !user.verified && <Redirect to="/verify" />}
            {isAuthenticated &&
              user.phone_no !== undefined &&
              !user.phone_no &&
              user.intrested_module !== undefined &&
              !user.intrested_module && (
                <>
                  <Redirect to="/complete-profile" />
                </>
              )}
            {isAuthenticated ? (
              <main>
                {user && user.phone_no && <Header />}
                <Component {...props} />
                {/* <Footer /> */}
              </main>
            ) : (
              <Redirect
                to={{
                  pathname: "/maintanance",
                  state: { from: props.location },
                }}
              />
            )}
          </Suspense>
        );
      }}
    />
  );
};

PrivateRoute.displayName = "Private Routes";

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    refreshing: store.generalActions.refreshing,
    user: store.auth.user,
  };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
